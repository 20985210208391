import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import ElementUI from "element-ui"
import axios from "axios"
import "element-ui/lib/theme-chalk/index.css"
import NProgress from "nprogress"
Vue.prototype.$axios = axios
Vue.prototype.$upload_img = "https://lg.csctesting.cn/api/upload_person_photos"
// Vue.prototype.$upload_img = "https://csctalent.globilityacademy.com/api/upload_person_photos"

Vue.use(ElementUI)

Vue.config.productionTip = false
NProgress.configure({
  easing: "ease",
  showSpinner: false,
})

Vue.prototype.$scrollTo = (x = 0, y = 0, type = "instant") => {
  window.scrollTo({
    top: x,
    left: y,
    behavior: type,
  })
}

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
// 正式环境清除所有console.log
if (process.env.NODE_ENV === "production") {
  if (window) {
    window.console.log = function () {}
  }
}
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")
