<template>
  <div class="">
    <div class="list_title flex">
      <span>推荐课程</span>
      <!-- <el-select v-model="coures_type" placeholder="请选择" @change="coures_type_event" class="to_left">
          <el-option
            v-for="item in type_arr"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select> -->
      <div class="screen flex">
        <!-- <div class="label">类型:</div> -->
        <div class="tags flex">
          <div class="tag" @click="coures_type_event(item)" :class="{ tag_avtive: coures_type == item.id }"
            v-for="(item, i) in type_arr" :key="i">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div v-loading="loading" element-loading-background="rgba(255, 255, 255, 0.5)" style="min-height: 200px">
      <div class="list">
        <div class="item" v-for="(item, i) in list" :key="i" @click="go_detials(item.id)">
          <div class="img_box">
            <img :src="item.cover" alt="" class="img" />
          </div>
          <div class="bottom">
            <div class="title">{{ item.goods_name }}</div>
            <div class="describe line-1">{{ item.short_description }}</div>
            <div class="info flex">
              <template v-if="item.teachers.length">
                <img v-if="item.teachers[0].avatar_url" :src="item.teachers[0].avatar_url" class="avatar" />
                <img v-else src="@/assets/tx.png" class="avatar" />
                <div class="name">{{ item.teachers[0].name }}</div>
                <div class="number">{{ item.order_goods_count }}人报名</div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { index_goods, index_recommend_categories } from "@/api/login";
export default {
  name: "course_list",
  components: {},
  props: {
    // courseList: {
    //   default: [],
    //   type: Array
    // }
  },
  data() {
    return {
      list: [],
      coures_type: 0,
      type_arr: [],
      loading: true,
    };
  },
  computed: {},
  watch: {},
  created() { },
  mounted() {
    this.get_options();
    this.getlist();
  },
  // 组件方法
  methods: {
    coures_type_event(item) {
      this.loading = true;
      this.coures_type = item.id;
      this.getlist();
    },
    get_options() {
      index_recommend_categories().then((res) => {
        this.type_arr = res.data;
        this.type_arr.unshift({
          name: "全部",
          id: 0,
        });
        this.coures_type = 0;
      });
    },
    getlist() {
      index_goods({
        category_id: this.coures_type ? this.coures_type : null,
      }).then((res) => {
        this.list = res.data;
        this.loading = false;
      });
    },
    go_detials(id) {
      let routeUrl = this.$router.resolve({
        path: "/course_details",
        query: {
          id: id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.list_title {
  padding: 30px 0;

  span {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #383838;
  }

  .to_left {
    margin-left: 30px;
  }

  .move {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 300;
    color: #383838;
    margin-left: 30px;
    cursor: pointer;
  }
}

.list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 200px;

  .item:hover {
    box-shadow: 0px 5px 10px 3px #dbdbdb;
  }

  .item {
    width: calc(25% - 30px);
    margin: 0 40px 30px 0;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;

    .img_box {
      border-radius: 10px 10px 0 0;
    }

    .img {
      height: 180px;
      width: 100%;
      border-radius: 10px 10px 0 0;
      object-fit: cover;
    }

    .bottom {
      padding: 15px 10px;

      .title {
        color: #383838;
        font-size: 16px;
        margin-bottom: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .describe {
        color: #909090;
        font-size: 14px;
        margin-bottom: 15px;
      }

      .info {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #383838;

        .name {
          margin-right: 30px;
        }

        .avatar {
          width: 32px;
          height: 32px;
          border-radius: 32px;
          margin-right: 10px;
        }
      }
    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}

.screen {
  margin-left: 30px;

  .label {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    width: 40px;
  }

  .tags {
    flex-wrap: wrap;

    .tag {
      padding: 5px 10px;
      background: #ffffff;
      border: 1px solid #d7d7d7;
      border-radius: 6px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #909090;
      margin-right: 10px;
      cursor: pointer;
    }

    .direction {
      padding: 5px 15px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #909090;
      cursor: pointer;
    }

    .direction:hover {
      color: #103994;
    }

    .direction_avtive {
      color: #103994;
    }

    .tag_avtive {
      background: #103994;
      color: #fff;
      border: 1px solid #103994;
    }

    .tag:hover {
      border: 1px solid #103994;
    }
  }
}
</style>
